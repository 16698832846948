/**
 * Helpers
 */
const isBrowser = typeof window !== "undefined"
const isObject = (value) => {
  return (value === Object(value) && typeof value === 'object' && JSON.stringify(value)[0] === "{")
}

/**
 * Local Storage Key constant
 * @description Local Storage Key used to persist state in window.localStorage
 */
export const LOCAL_STORAGE_KEY = 'baltcoda';

/**
 * @class BaboonStorage
 * @description save use of window.localStorage
 */
class BaboonStorage {

  /**
   * Storage
   */
  storage;

  /**
   * @constructor
   */
  constructor() {
    if (!(isBrowser && typeof window.localStorage !== 'undefined')) {
      // @ts-expect-error
      this.storage = void 0;

      return;
    }

    this.storage = window.localStorage;
  }

  /**
   * Check if key exists in local storage
   * @param  string key
   * @return boolean
   */
  has(key) {
    if (!this.storage || !this.storage.getItem(key)) {
      return false;
    }

    return true;
  }

  /**
   * Retrive an object from local storage.
   * @param  string key
   * @return mixed
   */
  get(key) {
    if (this.has(key)) {
      return JSON.parse(this.storage.getItem(key));
    }

    return void 0;
  }

  /**
   * Save some value to local storage.
   * 
   * @param string key    
   * @param string value
   */
  set(key, value) {
    if (!this.storage) {
      return
    }

    if (value === undefined) {
      throw new Error("[BaboonStorage]: Can't store undefined value");
    }

    if (!isObject(value)) {
      throw new Error(`[BaboonStorage]: only values of JSON type object is supported.`);
    }

    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Remove element from local storage.
   * @param string key 
   */
  remove(key) {
    if (this.has(key)) {
      this.storage.removeItem(key);
    }
  }
}

/**
 * Baboon Storage
 * @description wrapper for 
 * @exports 
 */

const s = new BaboonStorage();
export default s;