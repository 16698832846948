import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/playfair-display/400.css';
import '@fontsource/playfair-display/500.css';
import '@fontsource/playfair-display/600.css';
import "./src/styles/globals.css";

import * as React from "react"
import AppProvider from "~/context/app";

/**
 * Gatsby's wrapRootElement hook which wraps root node in a component
 * 
 * @param {React.ReactNode} element 
 * @returns React.ReactNode
 */
export const wrapRootElement = (props) => {
  return (
    <AppProvider>{props.element}</AppProvider>
  )
};