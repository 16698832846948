import * as React from "react"
import { ErrorBoundary } from "@sentry/gatsby";

import BrowserStorage, { LOCAL_STORAGE_KEY } from "~/utils/storage";
import { assertNever } from "~/utils/helpers";

/**
 * default state
 */
const defaultValues = {
  state: {
    loading: true,
    isBraveWallet: undefined,
    innerWidth: undefined,
    innerHeight: undefined,
    theme: undefined,
    banner: {
      seen: false,
      timestamp: undefined
    },
    cookie: {
      seen: false,
      timestamp: undefined
    },
    newsletter: {
      subscribed: false,
      timestamp: undefined
    }
  },
  dispatch: (props) => void 0
}

/**
 * State Reducer
 * @description handles state changes
 * 
 * @param {StateInterface} state
 * @param {ActionInterface} action
 * @returns 
 */
const stateReducer = (state, action) => {
  switch (action.type) {
    case 'SETUP': {
      return {
        ...state,
        loading: action.payload.loading,
        theme: action.payload.theme,
        isBraveWallet: action.payload.isBraveWallet,
        innerWidth: action.payload.innerWidth,
        innerHeight: action.payload.innerHeight
      }
    }

    case 'LOADING': {
      return {
        ...state,
        loading: action.payload
      }
    }

    case 'COOKIE': {
      return {
        ...state,
        cookie: {
          seen: action.payload || false,
          timestamp: Date.now()
        }
      }
    }

    case 'BANNER': {
      return {
        ...state,
        banner: {
          seen: action.payload || false,
          timestamp: Date.now()
        }
      }
    }

    case 'NEWSLETTER.SUBSCRIBE': {
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          subscribed: action.payload.subscribed || false,
          timestamp: Date.now()
        }
      }
    }

    case 'SYNC_REQUEST': {
      return {
        ...state,
        ...action.payload
      }
    }

    default: {
      return assertNever(action)
    }
  }
}


/**
 * App Context
 * @description App context is used to share state between components
 */
export const AppContext = React.createContext(defaultValues);


/**
 * App Context Provider
 * @description App Context Provider is used to provide the App Context for all components.
 * @kudos https://dev.to/bigaru/creating-persistent-synchronized-global-store-using-react-hooks-in-typescript-209a
 * 
 * @param {React.PropsWithChildren<{}>} props
 * @returns 
 */
const AppProvider = ({ children }) => {

  // instatiate state with default values or values from local storage
  const [state, dispatch] = React.useReducer(stateReducer, defaultValues.state, (state) => {
    const data = BrowserStorage.get(LOCAL_STORAGE_KEY) || defaultValues.state;

    return {
      ...state,
      ...data,
    }
  });

  // persist state to local storage
  React.useEffect(() => {
    BrowserStorage.set(LOCAL_STORAGE_KEY, state);

    return () => {
      // cleaning up the listeners here
    }
  }, [state]);

  // // use the newest data on every LocalStorage change
  // React.useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     const newData = BrowserStorage.get(LOCAL_STORAGE_KEY);

  //     if (newData) {
  //       dispatch({ type: 'SYNC_REQUEST', payload: newData })
  //     }
  //   });

  //   return () => {
  //     window.removeEventListener("storage");
  //   }
  // }, []);


  // render the app
  return (
    <ErrorBoundary>
      <AppContext.Provider value={{ state, dispatch }}>
        {children}
      </AppContext.Provider >
    </ErrorBoundary>
  )
}

/**
 * @exports AppProvider
 */
export default AppProvider;