/**
 * Merge CSS classes
 * 
 * @param classes 
 * @returns 
 * 
 * @example
 * cx(
 *  open ? 'text-gray-600' : 'text-gray-400',
 *  'ml-2 h-5 w-5 group-hover:text-gray-500'
 * )
 */
export const cx = (...args) => args.filter(Boolean).join(" ");

/**
 * Replase vars in content with values
 * 
 * @param content 
 * @param data 
 * @returns 
 */
export const markdownVars = (content, data) => {
  return content.replace(/:{3}(\w+):{3}/g, (match, key) => {
    const value = data[key]
    if (typeof value !== 'undefined') {
      return value
    }

    return match // guards against some unintentional prefix
  });
}

/**
 * throw error Taken from 
 * https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking
 */
export const assertNever = (x) => {
  throw new Error("Unexpected object: " + x);
}

/**
 * Check if browser
 */
export const isBrowser = typeof window !== "undefined"

/**
 * Pseudo-random string generator
 * 
 * @param {number} length 
 * @returns {string}
 */
export const randomString = (length) => {
  if (length > 11) {
    throw new Error('Random string length must be less than 11');
  }

  return Math.random().toString(36).replace('0.', "").substring(0, length);
}